<template>
  <div class="profile">
    <h5>{{ $t(`profile.header_title`) }}</h5>
    <b-card>
      <b-alert
        v-model="showProfileAlert"
        :variant="profileAlertType"
        dismissible
        >{{ profileAlertMessage }}</b-alert
      >

      <b-form @submit.prevent>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <b-form-group
              id="firstName"
              :label="$t(`profile.first_name-label`)"
              label-for="firstName"
              label-class="form-label required"
            >
              <b-form-input
                id="firstName"
                v-model="profileData.firstName"
                :placeholder="$t(`profile.first_name-placeholder`)"
                :class="{ 'is-invalid': $v.profileData.firstName.$error }"
                @keypress="allowOnlyAlphabetic"
              ></b-form-input>
              <b-form-invalid-feedback id="firstName-feedback">
                {{ $t(`profile.first_name-required-error-msg`) }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-sm-12 col-md-6">
            <b-form-group
              id="lastName"
              :label="$t(`profile.last_name-label`)"
              label-for="lastName"
              label-class="form-label required"
            >
              <b-form-input
                id="lastName"
                v-model="profileData.lastName"
                :class="{ 'is-invalid': $v.profileData.lastName.$error }"
                @keypress="allowOnlyAlphabetic"
                :placeholder="$t(`profile.last_name-placeholder`)"
              ></b-form-input>
              <b-form-invalid-feedback id="firstName-feedback">
                {{ $t(`profile.last_name-required-error-msg`) }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-sm-12 col-md-6">
            <b-form-group
              id="emailId"
              :label="$t(`profile.email-label`)"
              label-for="emailId"
              label-class="form-label"
            >
              <b-form-input
                id="emailId"
                type="email"
                v-model="profileData.emailId"
                :placeholder="$t(`profile.email-placeholder`)"
                disabled
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="d-flex flex-row-reverse">
          <b-button @click="handleProfileSave" variant="brand-primary">{{
            $t(`profile.save_profile-btn-label`)
          }}</b-button>
        </div>
      </b-form>
    </b-card>
    <h5 class="mt-3">{{ $t(`profile.change-password_header_title`) }}</h5>
    <b-card>
      <b-alert
        v-model="showPasswordAlert"
        :variant="passwordAlertType"
        dismissible
        >{{ passwordAlertMessage }}</b-alert
      >
      <b-form @submit.prevent>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <b-form-group
              id="currentPassword"
              :label="$t(`profile.current_password-label`)"
              label-for="currentPassword"
              label-class="form-label required"
            >
              <b-form-input
                id="currentPassword"
                type="password"
                v-model="passwordForm.currentPassword"
                :placeholder="$t(`profile.current_password-placeholder`)"
                :class="{
                  'is-invalid': $v.passwordForm.currentPassword.$error,
                }"
              ></b-form-input>
              <b-form-invalid-feedback id="currentPassword-feedback">
                {{ $t(`profile.current_password-required-error-msg`) }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                id="currentPassword-feedback"
                class="d-block"
                v-if="wrongOldPasswordMessage != ''"
              >
                {{ wrongOldPasswordMessage }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <b-form-group
              id="newPassword"
              :label="$t(`profile.new_password-label`)"
              label-for="newPassword"
              label-class="form-label required"
            >
              <b-form-input
                id="newPassword"
                type="password"
                v-model="passwordForm.newPassword"
                :class="{ 'is-invalid': $v.passwordForm.newPassword.$error }"
                :placeholder="$t(`profile.new_password-placeholder`)"
              ></b-form-input>
              <b-form-invalid-feedback
                id="newPassword-feedback"
                v-if="!$v.passwordForm.newPassword.required"
              >
                {{ $t(`profile.new_password-required-error-msg`) }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                id="newPassword-feedback"
                v-else-if="!$v.passwordForm.newPassword.valid"
              >
                The new password entered does not meet the security requirements
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-sm-12 col-md-6">
            <b-form-group
              id="confirmPassword"
              :label="$t(`profile.confirm_password-label`)"
              label-for="confirmPassword"
              label-class="form-label required"
            >
              <b-form-input
                id="confirmPassword"
                type="password"
                v-model="passwordForm.confirmPassword"
                :placeholder="$t(`profile.confirm_password-placeholder`)"
                :class="{
                  'is-invalid': $v.passwordForm.confirmPassword.$error,
                }"
              ></b-form-input>
              <b-form-invalid-feedback
                id="newPassword-feedback"
                v-if="!$v.passwordForm.confirmPassword.required"
              >
                {{ $t(`profile.confirm_password-required-error-msg`) }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                id="newPassword-feedback"
                v-else-if="
                  !$v.passwordForm.confirmPassword.valid ||
                  !$v.passwordForm.confirmPassword.required
                "
              >
                The new password entered does not meet the security requirements
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                id="newPassword-feedback"
                v-else-if="
                  !$v.passwordForm.confirmPassword.sameAsPassword ||
                  !$v.passwordForm.confirmPassword.valid
                "
              >
                {{ $t(`profile.confirm_password-notmatch-error-msg`) }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p>
              Password must be at a minimum of 8 characters, with a complexity
              mix of at least one of each of the following (capital letter,
              lower case, number, special character(! @ # $ % ^ & *))
            </p>
          </div>
        </div>

        <div class="d-flex flex-row-reverse">
          <b-button @click="handlePasswordUpdate" variant="brand-primary">{{
            $t(`profile.save_password-btn-label`)
          }}</b-button>
        </div>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { required, sameAs, helpers } from "vuelidate/lib/validators";
export default {
  name: "Profile",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Profile",
  },
  beforeMount() {
    let profileData = Object.assign({}, this.user);
    let userStoreList = [];

    this.siteAccess.forEach((element) => {
      let obj = { storeId: element.SiteId, userId: profileData.Id };
      userStoreList.push(obj);
    });

    profileData.userId = profileData.id;
    profileData.userTypeId = profileData.userType;
    profileData.userStoreList = userStoreList;

    delete profileData.id;
    delete profileData.userType;

    this.profileData = profileData;
  },
  data() {
    return {
      showProfileAlert: false,
      profileAlertType: "",
      profileAlertMessage: "",
      showPasswordAlert: false,
      passwordAlertType: "",
      passwordAlertMessage: "",
      profileData: {},
      wrongOldPasswordMessage: "",
      passwordForm: {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
    };
  },
  methods: {
    ...mapActions("auth", ["updateProfile", "updatePassword"]),
    ...mapMutations(["setItem"]),
    ...mapMutations("auth", {
      authSetItem: "setItem",
    }),
    async handleProfileSave() {
      this.$v.profileData.$touch();

      if (this.$v.profileData.$invalid) return;

      this.setItem({ resource: "isLoading", payload: true });
      this.updateProfile(this.profileData)
        .then((response) => {
          this.showProfileAlert = true;

          if (response.status == 1) {
            this.profileAlertType = "success";
            this.profileAlertMessage = this.sytemMessages.profileUpdated;
          } else {
            this.profileAlertType = "danger";
            this.profileAlertMessage = response.error;
          }

          this.setItem({ resource: "isLoading", payload: false });
        })
        .catch(() => {
          this.setItem({ resource: "isLoading", payload: false });
        });
    },
    async handlePasswordUpdate() {
      this.wrongOldPasswordMessage = "";
      this.$v.passwordForm.$touch();

      if (this.$v.passwordForm.$invalid) return;

      this.setItem({ resource: "isLoading", payload: true });

      let passwordObject = {
        userId: this.user.id,
        newPassword: this.passwordForm.newPassword,
        oldPassword: this.passwordForm.currentPassword,
      };

      this.updatePassword(passwordObject)
        .then((response) => {
          this.showPasswordAlert = true;

          if (response.status == 1) {
            this.passwordAlertType = "success";
            this.passwordAlertMessage = this.sytemMessages.passwordUpdated;
          } else if (response.status == 2) {
            this.showPasswordAlert = false;
            this.wrongOldPasswordMessage = response.error;
          } else {
            this.passwordAlertType = "danger";
            this.passwordAlertMessage = response.error;
          }

          this.setItem({ resource: "isLoading", payload: false });
        })
        .catch(() => {
          this.setItem({ resource: "isLoading", payload: false });
        });
    },
  },
  computed: {
    ...mapGetters("auth", ["siteAccess"]),
  },
  validations: {
    profileData: {
      firstName: { required },
      lastName: { required },
    },
    passwordForm: {
      currentPassword: { required },
      newPassword: {
        required,
        valid: helpers.regex(
          "validPassword",
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/
        ),
      },
      confirmPassword: {
        required,
        valid: helpers.regex(
          "validPassword",
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/
        ),
        sameAsNewPassword: sameAs(function () {
          return this.passwordForm.newPassword;
        }),
      },
    },
  },
};
</script>
